import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'

import { SliderInput } from '../FormComponents'
import BarChart from '../BarChart'
import ResultSnippet, { resultTypes } from './ResultSnippet'
import Range from './Range'
import { toCurrency, formattedNumber } from '../../services/util'
import { calculatePredictedMonthlyData } from '../../services/revenue'
import { EGAnalytics, analyticEvents } from '../../services/analytics'
import { getMaxYaxisValue } from './util'

import './styles.scss'

// Using a debounce method to track total monthly visit change, because slider triggeres too many events when it's being used
const trackMonthlyVisitChange =  debounce((value) => EGAnalytics.track(analyticEvents.MONTHLY_VISIT_INTERACTION, {monthlyVisit: value}), 1000)

// Using a debounce method to track avearage order value change, because slider triggeres too many events when it's being used
const trackAverageOrderValue = debounce((value) => EGAnalytics.track(analyticEvents.AVERAGE_ORDER_VALUE_INTERACTION, {avgOrderValue: value}), 1000)

const trackInstagramFollowerChange = debounce((value) => EGAnalytics.track(analyticEvents.INSTAGRAM_FOLLOWER_INTERACTION, {followers: value}), 1000)

const trackEmailListChange = debounce((value) => EGAnalytics.track(analyticEvents.EXISTING_EMAIL_LIST_INTERACTION, {emailListSize: value}), 1000)


const RoiCalculator = props => {
  const { data } = props
  const [ totalMonthlyVisits, setTotalMonthlyVisits ] = useState(props.totalMonthlyVisits.default)
  const [ totalEmailListSubscriber, setTotalEmailListSubscriber ] = useState(props.totalEmailListSubscriber.default)
  const [ totalInstagramFollowers, setTotalInstagramFollowers ] = useState(props.totalInstagramFollowers.default)
  const [ averageOrderValue, setAverageOrderValue ] = useState(props.averageOrderValue.default)
  const [ revenueDetails, setRevenueDetails ] = useState({})

  const trackEmail = () => {
    EGAnalytics.track(analyticEvents.CONTACT_US_INTERACTION, {
      page: window.location.pathname,
      section: 'email us',
      contactMethod: 'Email'
    })
  }

  const onCalculation = useCallback(debounce((data) => {
    const revenueDetails = calculatePredictedMonthlyData(data)
    setRevenueDetails(revenueDetails)
  }, 300), [])

  useEffect(() => {
    const businessData = { totalMonthlyVisits, averageOrderValue, totalEmailListSubscriber, totalInstagramFollowers }
    onCalculation(businessData)
  }, [averageOrderValue, onCalculation, totalEmailListSubscriber, totalInstagramFollowers, totalMonthlyVisits])

  const handleMonthlyVisitChange = (value) => {
    const intNumber = parseInt(value) ? parseInt(value) : 0
    setTotalMonthlyVisits(intNumber)
    trackMonthlyVisitChange(intNumber)
  }

  const handleAverageOrderChange = (value) => {
    const floatNumber = parseFloat(value) ? parseFloat(value) : 0
    setAverageOrderValue(floatNumber)
    trackAverageOrderValue(floatNumber)
  }

  const handleInstagramFollowerChange = (value) => {
    const intNumber = parseInt(value) ? parseInt(value) : 0
    setTotalInstagramFollowers(intNumber)
    trackInstagramFollowerChange(intNumber)
  }

  const handleEmailListSubscriberChange = (value) => {
    const intNumber = parseInt(value) ? parseInt(value) : 0
    setTotalEmailListSubscriber(intNumber)
    trackEmailListChange(intNumber)
  }

  useEffect(() => {
    const businessData = { totalMonthlyVisits, averageOrderValue, totalEmailListSubscriber, totalInstagramFollowers }
    const revenueDetails = calculatePredictedMonthlyData(businessData)
    setRevenueDetails(revenueDetails)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const revenueDataset = revenueDetails.revenueMonthlyList || []
  const predictedTotalRevenue = revenueDataset.length ? revenueDataset.reduce((sum, revenue) => sum + revenue, 0) : 0
  const monthlySubscriberArray = revenueDetails.subscriberMonthlyList || []
  const lastMonthReachableCustomers = monthlySubscriberArray.length ? parseInt(monthlySubscriberArray[monthlySubscriberArray.length -1 ]) : 0
  const yAxisMaxZSFrevenue = getMaxYaxisValue(revenueDataset[revenueDataset.length-1])
  const yAxisMaxReachableCustomers = getMaxYaxisValue(lastMonthReachableCustomers)
  const totalSubscriberValue =  monthlySubscriberArray.length && monthlySubscriberArray[monthlySubscriberArray.length - 1]

  return (
    <>
      <div className='eg-roi-calculator'>
        <h1 className='main-title'>{data.title}</h1>
        <div className='form'>
          {/* left panel */}
          <div className='panel'>
            <div className='title'>Store Information</div>
            <div className='form-items'>
              <div className='form-item'>
                <div className='label'>Total Monthly Visits</div>
                <SliderInput
                  className='input'
                  min={ props.totalMonthlyVisits.min }
                  max={ props.totalMonthlyVisits.max }
                  step={1}
                  name='last-month-revenue'
                  value={ totalMonthlyVisits }
                  onChange={ handleMonthlyVisitChange }
                />
                <Range min={'1k'} max={'20k'} />
              </div>
              <div className='form-item'>
                <div className='label'>Email List Subscribers</div>
                <SliderInput
                  className='input'
                  min={ props.totalEmailListSubscriber.min }
                  max={ props.totalEmailListSubscriber.max }
                  name='existing-customers'
                  step={1}
                  value={ totalEmailListSubscriber }
                  onChange={ handleEmailListSubscriberChange }
                />
                <Range min={'0'} max={'10k'} />
              </div>
              <div className='form-item'>
                <div className='label'>Instagram Followers</div>
                <SliderInput
                  className='input'
                  min={ props.totalInstagramFollowers.min }
                  max={ props.totalInstagramFollowers.max }
                  name='customers-per-year'
                  step={1}
                  value={ totalInstagramFollowers }
                  onChange={ handleInstagramFollowerChange }
                />
                <Range min={'0'} max={'10k'} />
              </div>

              <div className='form-item'>
                <div className='label'>Average Order Size</div>
                <SliderInput
                  className='input dollar-input'
                  min={ props.averageOrderValue.min }
                  max={ props.averageOrderValue.max }
                  name='average-order'
                  step={5}
                  value={ averageOrderValue }
                  onChange={ handleAverageOrderChange }
                />
                <Range min={'0'} max={'100'} />
              </div>

            </div>

            {/* <Button className='button' text='CALCULATE' onClick={onCalculation}/> */}
          </div>

          {/* right panel */}
          <div className='panel'>
            <div className='title'>Results after the first year</div>
            <div className='results'>
              <ResultSnippet
                label='Total SMS Revenue'
                value={ predictedTotalRevenue ? toCurrency(predictedTotalRevenue) : '-' }
                type={ resultTypes.REVENUE }
              />
              <ResultSnippet
                label='Total SMS Subscribers'
                value={ totalSubscriberValue ? formattedNumber(totalSubscriberValue) : '-'}
                type={ resultTypes.GROWTH }
              />
            </div>
            <div className='graph-wrapper'>
              <div className='revChart'>
                <div className='label-small'>Incremental Messaging Revenue</div>
                <BarChart
                  dataset={ revenueDataset }
                  maxYVal={ yAxisMaxZSFrevenue }
                  deltaY={ yAxisMaxZSFrevenue ? parseInt(yAxisMaxZSFrevenue/5) : 5 }
                  barColor='#2bbfb3'
                />
              </div>
              <div className='revChart'>
                <div className='label-small'>Monthly Subscribers</div>
                <BarChart
                  dataset={ monthlySubscriberArray }
                  maxYVal={ yAxisMaxReachableCustomers }
                  deltaY={ parseInt(yAxisMaxReachableCustomers/5) ?  parseInt(yAxisMaxReachableCustomers/5) : 5}
                  barColor='#70daf1'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='support-wrapper'>
          Something is outdated or wrong&#63;
          <div>
            email us at {' '}
            <a href='mailto:support@zerostorefront.com' onClick={trackEmail}>support@zerostorefront.com</a>
          </div>
        </div>
      </div>
    </>
  )
}

RoiCalculator.propTypes = {
  data: PropTypes.object.isRequired,
  totalMonthlyVisits: PropTypes.object,
  totalEmailListSubscriber: PropTypes.object,
  totalInstagramFollowers: PropTypes.object,
  averageOrderValue: PropTypes.object,
  demoCtaData: PropTypes.object
}

export default RoiCalculator
