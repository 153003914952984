import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import './styles.scss'

const TopHowTo = props => {
  const { data = {}} = props

  const renderStep = (data, index) => {
    return (
      <div className='eg-b-step' key={ `bottom-how-to-step-${index}` }>
        <div className='img-wrapper'>
          <img
            data-src= {`${get(data, 'image.file.url')}?w=131`}
            className='gray-circle lazyload'
            alt={data.title}
          />
        </div>
        <div className='title'>{data.title}</div>
        <div className='paragraph'>{data.paragraph.paragraph}</div>
      </div>
    )
  }

  const subtitle = get(data, 'subtitle.subtitle')
  return (
    <div className='eg-bottom-how-to' >
      <div className='title'>{data.title}</div>
      { subtitle && <div className='description'>{subtitle} </div>}
      <div className='steps'>
        {data.steps.map((step, i) => renderStep(step, i))}
      </div>
    </div>
  )
}

TopHowTo.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string
}

export default TopHowTo
