import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { PATHS } from '../../constant'
import './styles.scss'

const PressCard = (props) => {
  const { data } = props
  const cardImage= data.image && data.image.file.url
  const  isZSFPressPage = !data.hideContents
  const link = isZSFPressPage ? `${PATHS.PRESS}/${data.urlSlug}` : data.sourceLink 

  return (
    <a href={link} target = { isZSFPressPage ? '' : '_blank' }  rel = "noopener noreferrer" className='zsf-press-card'>
      <img src={cardImage} alt='card' className='image'/>
      <div className='details'>
        <img src={get(data,'sourceLogo.file.url')} align='left' className='media' alt='media' />
        <div className='title'>
          {data.title}
        </div>
        <div className='bottom-row'>
          <div className= 'title'>{ isZSFPressPage ? 'Read more' : 'Read article'}</div>
          <div className = 'arrow'/>
        </div>
      </div>
    </a>
  )
}

PressCard.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PressCard
