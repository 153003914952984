import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'


import MainLayout from '../components/MainLayout'
import RoiCalculator from '../components/RoiCalculator'
import ZsfPress from '../components/Home/ZsfPress'
import BottomHowTo from '../components/Home/BottomHowTo'


export default props => {
  const headerData = _.get(props, 'data.allContentfulHomeV3.edges[0].node')
  const pageData = _.get(props, 'data.allContentfulRoiCalculator.edges[0].node')

  return (
    <MainLayout data={ {...headerData, title: 'ZeroStorefront | ROI Calculator'} }>
      <RoiCalculator
        data={pageData}
        totalMonthlyVisits={ { min: 1000, max: 20000, default: 10000} }
        totalEmailListSubscriber={ { min: 0, max: 10000, default: 5000} }
        totalInstagramFollowers={ { min: 0, max: 10000, default: 5000} }
        averageOrderValue={ { min: 0, max: 100, default: 35} }
      />
      <ZsfPress data={pageData.press}/>
      <BottomHowTo data={pageData.howItWorksBottom} />
    </MainLayout>
  )
}

export const pageQuery = graphql`
  query roiCalculatorPage {
    allContentfulHomeV3 {
      edges {
        node {
          title
          contentful_id
          hero {
            cta
          }
        }
      }
    }

     allContentfulRoiCalculator {
      edges {
        node {
          title
          press {
            title
            articles {
              title
              hideContents
              urlSlug
              image {
                file {
                  url
                }
              }
              source
              sourceLink
              sourceLogo {
                file {
                  url
                }
              }
            }
          }
          howItWorksBottom {
            title
            subtitle {
              subtitle
            }
            steps {
              image {
                file {
                  url
                }
              }
              title
              paragraph {
                paragraph
              }
            }
          }
        }
      }
    }
  }
`
