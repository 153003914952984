import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

export const resultTypes = {
  REVENUE: 'revenue',
  GROWTH: 'growth'
}

const ResultSnippet = props => {
  const {
    label,
    value,
    type
  } = props

  return (
    <div className='eg-result-snippet'>
      <div className={ `logo ${type}-icon` } />
      <div className='content'>
        <div className='label'>{ label }</div>
        <div className='value'>{ value }</div>
      </div>
    </div>
  )
}

ResultSnippet.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.oneOf(Object.values(resultTypes))
}

export default ResultSnippet
