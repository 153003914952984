import React from 'react'
import PropTypes from 'prop-types'
// import get from 'lodash/get'

import PressCard from '../../PressCard'
import './styles.scss'

const ZsfPress = props => {
  const { data = {}} = props

  return (
    <div className='zsf-press'>
      <div className='title'>
        {data.title}
      </div>
      <div className='press-items'>
        {data.articles.map((item, index) => <PressCard data={item} key={index}/>)}
      </div>
    </div>
  )
}

ZsfPress.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ZsfPress
