// Calculation reference guide |> https://docs.google.com/spreadsheets/d/1ovPvp5N7ggq46RbwE7TQdYOsX1gLuk8AbG8JTMa0GEc/edit#gid=149469725

const AVERAGE_CAMPAIGNS_PER_MONTH = 4

const CONVERSION_RATES = {
  MONTHLY_VISIT_CONVERSION: .075,
  EMAIL_CONVERSION: .055,
  INSTAGRAM_CONVERSION: .025,
  ORDER_CONVERSION: .05,
}

const CONVERSION_DECAY = {
  MONTHLY_VISIT_CONVERSION: 0.003,
  EMAIL_CONVERSION: 0.004,
  INSTAGRAM_CONVERSION: 0.0025,
}

export const calculatePredictedMonthlyData = (revenueDetails) => {
  const { totalMonthlyVisits , totalEmailListSubscriber, totalInstagramFollowers, averageOrderValue } = revenueDetails
  // Calculations for first month
  const totalSubscriberFromVisit = totalMonthlyVisits * CONVERSION_RATES.MONTHLY_VISIT_CONVERSION
  const totalSubscriberFromEmail = totalEmailListSubscriber * CONVERSION_RATES.EMAIL_CONVERSION
  const totalSubcriberFromInstagram = totalInstagramFollowers * CONVERSION_RATES.INSTAGRAM_CONVERSION
  const totalSMSSubscriber = totalSubscriberFromVisit + totalSubscriberFromEmail + totalSubcriberFromInstagram
  const totalMonthlyRevenue = AVERAGE_CAMPAIGNS_PER_MONTH * totalSMSSubscriber * averageOrderValue * CONVERSION_RATES.ORDER_CONVERSION
  const monthlyPredictedData = [
    {
      totalSMSSubscriber: Math.ceil(totalSMSSubscriber),
      totalSubscriberFromVisit: Math.ceil(totalSubscriberFromVisit),
      totalSubscriberFromEmail: Math.ceil(totalSubscriberFromEmail),
      totalSubcriberFromInstagram: Math.ceil(totalSubcriberFromInstagram),
      totalMonthlyRevenue: Math.ceil(totalMonthlyRevenue)
    }
  ]

  const revenueMonthlyList = [
    Math.ceil(totalMonthlyRevenue)
  ]

  const subscriberMonthlyList = [
    Math.ceil(totalSMSSubscriber),
  ]

  // calculations for remaining months
  for(let month = 1; month < 12; month++) {
    const lastMonthPredictedData = monthlyPredictedData[month-1]
    const totalSubscriberFromVisit = lastMonthPredictedData.totalSubscriberFromVisit + (totalMonthlyVisits * (CONVERSION_RATES.MONTHLY_VISIT_CONVERSION - month*CONVERSION_DECAY.MONTHLY_VISIT_CONVERSION))
    const totalSubscriberFromEmail = lastMonthPredictedData.totalSubscriberFromEmail + (totalEmailListSubscriber * (CONVERSION_RATES.EMAIL_CONVERSION - month*CONVERSION_DECAY.EMAIL_CONVERSION))
    const totalSubcriberFromInstagram = lastMonthPredictedData.totalSubcriberFromInstagram + (totalInstagramFollowers * (CONVERSION_RATES.INSTAGRAM_CONVERSION - month*CONVERSION_DECAY.INSTAGRAM_CONVERSION))
    const totalSMSSubscriber = totalSubscriberFromVisit + totalSubscriberFromEmail + totalSubcriberFromInstagram
    const totalMonthlyRevenue = AVERAGE_CAMPAIGNS_PER_MONTH * totalSMSSubscriber * averageOrderValue * CONVERSION_RATES.ORDER_CONVERSION
    monthlyPredictedData.push({
      totalSMSSubscriber: Math.ceil(totalSMSSubscriber),
      totalSubscriberFromVisit: Math.ceil(totalSubscriberFromVisit),
      totalSubscriberFromEmail: Math.ceil(totalSubscriberFromEmail),
      totalSubcriberFromInstagram: Math.ceil(totalSubcriberFromInstagram),
      totalMonthlyRevenue: Math.ceil(totalMonthlyRevenue)
    })
    subscriberMonthlyList.push(Math.ceil(totalSMSSubscriber))
    revenueMonthlyList.push(Math.ceil(totalMonthlyRevenue))
  }

  return {
    monthlyPredictedData,
    subscriberMonthlyList,
    revenueMonthlyList
  }
}
