import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

Range.propTypes = {
  min: PropTypes.string,
  max: PropTypes.string,
}

function Range(props) {
  const { min, max } = props
  return (
    <div className='eg-slider-range'>
      <div>{min}</div>
      <div>+{max}</div>
    </div>
  )
}

export default Range
