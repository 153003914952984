
export const getMaxYaxisValue = (value) => {
  if(value < 3000) {
    return 3000
  }else if(value < 5000) {
    return 5000
  } else if(value < 15000) {
    return 15000
  } else if(value < 25000) {
    return 25000
  } else if(value < 50000) {
    return 50000
  } else if(value < 150000) {
    return 150000
  } else if (value < 300000) {
    return 300000
  } else if (value < 500000) {
    return 500000
  } else if (value < 800000) {
    return 800000
  } else if (value < 1000000) {
    return 1000000
  } else if (value < 2000000) {
    return 2000000
  } else if (value < 3000000) {
    return 3000000
  } else if (value < 4000000) {
    return 4000000
  } else if (value < 5000000) {
    return 5000000
  } else if (value < 6000000) {
    return 6000000
  } else if (value < 7000000) {
    return 7000000
  } else if (value < 8000000) {
    return 8000000
  } else if (value < 9000000) {
    return 9000000
  } else {
    return value
  }
}
