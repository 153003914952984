import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

import './styles.scss'

const monthLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

const isBrowser = () => typeof window !== 'undefined'

const MOBILE_MAX_WIDTH = 768

const dimensionsDesktop = {
  width: 340,
  height: 200,
  margin: { top: 20, right: 10, bottom: 20, left: 70 },
  yAxisPadding: '-15px',
  // xAxisPadding: '-20px',
  barWidth: 12
}

const dimensionsMobile = {
  width: 300,
  height: 275,
  margin: { top: 20, right: 10, bottom: 20, left: 40 },
  yAxisPadding: '-5px',
  barWidth: 12
}

const BarChart = props => {
  const chartRef = useRef()
  const [isMobile, setIsMobile] = useState(isBrowser() ? window.innerWidth < MOBILE_MAX_WIDTH : false)
  const [dimensions, setDimensions] = useState(isMobile ? dimensionsMobile : dimensionsDesktop)
  const { dataset, barColor, maxYVal = 50, deltaY = 10 } = props

  const handleResize = useCallback(() => {
    if (!isMobile && window.innerWidth < MOBILE_MAX_WIDTH) {
      setIsMobile(true)
      setDimensions(dimensionsMobile)
    } else if (isMobile && window.innerWidth >= MOBILE_MAX_WIDTH) {
      setIsMobile(false)
      setDimensions(dimensionsDesktop)
    }
  }, [isMobile])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    const { margin } = dimensions
    const width = dimensions.width - margin.left - margin.right
    const height = 300 - margin.top - margin.bottom
    if (chartRef.current) { chartRef.current.innerHTML = '' }
    try {
      const g = d3.select(chartRef.current).append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`)

      // x axis
      let xScale = d3.scaleLinear().domain([0, 13]).range([0, width])
      const xAxisGenerator = d3.axisBottom(xScale)
      xAxisGenerator.tickSize(0)
      xAxisGenerator.tickValues([...Array(12).keys()].map(k => k + 1))
      xAxisGenerator.tickFormat((d, i) => monthLabels[i])
      let xAxis = g.append('g').call(xAxisGenerator)
      xAxis.attr('transform', `translate(0, ${height - 20})`)
      xAxis.attr('class', 'x-axis')
      xAxis.selectAll('.tick text').attr('dy', '20px')
      xAxis.selectAll('.tick text').attr('font-size', '8px')
      if (isMobile) {
        xAxis.selectAll('.tick text').attr('font-size', '8px')
      }

      // y axis
      let yScale = d3.scaleLinear().domain([maxYVal, 0]).range([0, height - 20])
      const yAxisGenerator = d3.axisLeft(yScale)
      yAxisGenerator.tickSize(0)
      let tickValues = []
      for (let k = 0; k <= parseInt(maxYVal / deltaY); k++) {
        tickValues.push(k * deltaY)
      }

      // yAxisGenerator.tickValues([...Array(maxYVal + 1).keys()].filter(k => k % deltaY === 0))
      yAxisGenerator.tickValues(tickValues)
      const yAxis = g.append('g').call(yAxisGenerator)
      yAxis.attr('class', 'y-axis')
      yAxis.selectAll('.tick text').attr('dx', dimensions.yAxisPadding)
      if (isMobile) {
        yAxis.selectAll('.tick text').attr('font-size', '6px')
      }

      // make y-axis line "invisible"
      g.select('.y-axis path').attr('stroke', '#fff')

      // dashed lines for each tick
      for (let i = deltaY; i <= maxYVal; i += deltaY) {
        g.append('line')
          .style('stroke', '#e1e5e9')
          .style('stroke-dasharray', ('3, 3'))
          .attr('x1', xScale(0))
          .attr('y1', yScale(i))
          .attr('x2', xScale(width))
          .attr('y2', yScale(i))
      }

      // draw the bars
      g.selectAll('rect')
        .data(dataset)
        .enter()
        .append('rect')
        .attr('fill', barColor)
        .attr('class', 'sBar')
        .attr('rx', '4')
        .attr('x', (d, i) => xScale(i) - 10)
        .attr('y', (d, i) => yScale(d))
        .attr('width', dimensions.barWidth)
        .attr('height', (d, i) => height - yScale(d) - 20)
        .attr('transform', 'translate(25, 0)')
    } catch(error) {
      console.error(error)
    }
  }, [dataset, barColor, maxYVal, deltaY, dimensions, isMobile])

  return (
    <div className='eg-bar-chart' ref={ chartRef }>
    </div>
  )
}

BarChart.propTypes = {
  dataset: PropTypes.array,
  barColor: PropTypes.string,
  maxYVal: PropTypes.number,
  deltaY: PropTypes.number
}

export default BarChart
